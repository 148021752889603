import styles from './styles.module.css'
import { cn } from '@/lib/utils'

interface Props {
  className?: string
  content: string | null | undefined
}

export const RichText = ({ className, content }: Props) => {
  if (!content) {
    return null
  }
  const __html = content.replace(/{{ICON}}/g, ArrowUpRightFromSquare)

  return (
    <div
      className={cn(styles['rich-text-base'], className)}
      dangerouslySetInnerHTML={{ __html }}
    ></div>
  )
}

// from 'flowbite-react-icons/outline'
const ArrowUpRightFromSquare =
  '<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="size-5 ml-1 mr-0.5 inline-block" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" /></svg>'
