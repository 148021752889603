'use client'

// import Link from 'next/link'
import { Dropdown } from 'flowbite-react'
import type { QuickLinksBarProps } from './'
import InfoCircle from '@/components/Icons/Custom/InfoCircle'
import { CMSHref } from '@/components/CMSLink'
import { getIcon, IconSets } from '@/components/Icons'

export default function StickyFloater({
  quickLinks,
}: Readonly<QuickLinksBarProps>) {
  return (
    <Dropdown
      label=""
      dismissOnClick={false}
      placement="top"
      renderTrigger={() => (
        <button className="fixed bottom-2 right-2 z-50 rounded-full bg-primary p-4 text-white md:bottom-4 md:right-4">
          <InfoCircle className="size-9" strokeWidth={2} />
        </button>
      )}
    >
      {(quickLinks?.length ?? 0) > 0 &&
        quickLinks?.map(({ id, icon, link }) => {
          if (!link) return null
          const [iconSet, iconName] = icon?.split('_') ?? ''
          if (!iconSet || !iconName) return null
          const Icon = getIcon(iconSet as keyof IconSets, iconName)
          if (!Icon) return null
          return (
            <Dropdown.Item key={id} as={'a'} href={CMSHref(link)} icon={Icon}>
              {link.label}
            </Dropdown.Item>
          )
        })}
    </Dropdown>
  )
}
