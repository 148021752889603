'use client'

// import Link from 'next/link'
import Image from 'next/image'
import { useTransition } from 'react'
import { useSearchParams, useRouter } from 'next/navigation'
import { Refresh, ArrowRight } from 'flowbite-react-icons/outline'
import { Button } from 'flowbite-react'
import type { PrivateProduct } from './AccountFinderPrivate'
import type { CorporateProduct } from './AccountFinderCorporate'
import { cmsProxyUrl } from '@/lib/utils'

// Ideally we would define the graphql product fragment here
// and import it from the server components.
// However, Next.js doesn't allow sharing these fragments from client components with server components
// so instead we have to duplicate the type definitions
// eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
type Product = PrivateProduct | CorporateProduct

interface RecommendationNode {
  answer: string
  recommendation: Product | null
}

interface QuestionNode {
  answer: string
  question: string
  answers: DecisionNode[]
}

type DecisionNode = RecommendationNode | QuestionNode

interface DecisionTree {
  question: string
  answers: DecisionNode[]
}

export const AccountFinder = ({
  decisionTree,
}: {
  decisionTree: DecisionTree
}) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [isPending, startTransition] = useTransition()

  // the entire state is stored in the URL
  const path = searchParams.get('path')?.split(',') ?? []
  const hasStarted = searchParams.get('started') === '1'

  // the current node in the decision tree is determined by the path param
  const currentNode = getCurrentNodeFromPath(path)
  const recommendation =
    'recommendation' in currentNode ? currentNode.recommendation : null

  function getCurrentNodeFromPath(path: string[]) {
    let node: DecisionNode | DecisionTree = decisionTree
    for (const index of path) {
      if ('answers' in node) {
        node = node.answers[parseInt(index)] ?? node
      }
    }
    return node
  }

  const handleAnswer = (index: number) => {
    if ('answers' in currentNode) {
      const nextNode = currentNode.answers[index]
      if (!nextNode) return null
      const params = new URLSearchParams(searchParams)
      const newPath = [...path, String(index)]
      params.set('path', newPath.join(','))
      startTransition(() => {
        router.push(`?${params.toString()}`, { scroll: false })
      })
    }
  }

  const handleStart = () => {
    const params = new URLSearchParams(searchParams)
    params.set('started', '1')
    startTransition(() => {
      router.push(`?${params.toString()}`, { scroll: false })
    })
  }

  const handleBack = () => {
    startTransition(() => {
      const newPath = path.slice(0, -1)
      const params = new URLSearchParams(searchParams)
      params.set('path', newPath.join(','))
      if (newPath.length === 0) {
        params.delete('path')
      }
      if (hasStarted && !searchParams.get('path')) {
        params.delete('started')
      }
      router.push(`?${params.toString()}`, { scroll: false })
    })
  }

  return (
    <>
      <div
        className="mx-auto flex min-h-72 max-w-[48rem] flex-col items-center justify-center border-y py-7 md:py-14"
        key={path.join(',') + hasStarted}
      >
        {isPending ? (
          <Pending />
        ) : (
          <>
            {!hasStarted && <Start handleStart={handleStart} />}
            {hasStarted &&
              (recommendation ? (
                <Recommendation recommendation={recommendation} />
              ) : (
                <Question
                  currentNode={currentNode}
                  handleAnswer={handleAnswer}
                />
              ))}
          </>
        )}
      </div>
      <div className="box-content flex min-h-10 grow flex-col items-center justify-center pt-7 md:pt-14">
        {!isPending && hasStarted && <Back handleBack={handleBack} />}
      </div>
    </>
  )
}

const Pending = () => {
  return (
    <Refresh
      className="m-2 mx-auto size-10 animate-spin text-gray-400"
      strokeWidth={1}
    />
  )
}

const Back = ({ handleBack }: { handleBack: () => void }) => {
  return (
    <Button outline className="mx-auto" onClick={handleBack}>
      Zurück
    </Button>
  )
}

const Start = ({ handleStart }: { handleStart: () => void }) => {
  return (
    <div className="flex flex-col items-center space-y-6">
      <h3 className="text-center text-3xl font-bold">
        Der Bank Burgenland Konto Finder
      </h3>
      <p className="text-center text-xl">
        Nicht sicher, welches Konto am besten zu Ihren Bedürfnissen passt? Unser
        Konto-Finder hilft Ihnen, in nur wenigen Schritten das perfekte Konto zu
        identifizieren.
      </p>
      <Button size="lg" onClick={handleStart}>
        Jetzt starten <ArrowRight className="ml-1.5 size-5" />
      </Button>
    </div>
  )
}

const Question = ({
  currentNode,
  handleAnswer,
}: {
  currentNode: DecisionNode | DecisionTree
  handleAnswer: (index: number) => void
}) => {
  return (
    <div className="center flex flex-col items-center">
      {'question' in currentNode && (
        <h4 className="mb-3 text-center text-3xl font-bold md:mb-6">
          {currentNode.question}
        </h4>
      )}
      {'answers' in currentNode && (
        <div className="flex gap-4">
          {currentNode.answers.map((node, index) => (
            <Button
              size="lg"
              key={node.answer}
              onClick={() => handleAnswer(index)}
            >
              {node.answer}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

const Recommendation = ({ recommendation }: { recommendation: Product }) => {
  return (
    <div className="flex gap-5 sm:gap-10">
      {recommendation.image && (
        <a href={recommendation.page?.path ?? ''} className="min-w-fit">
          <Image
            src={cmsProxyUrl(recommendation.image.sizes?.square?.url) ?? ''}
            alt={recommendation.image?.alt ?? ''}
            width={96}
            height={96}
            className="w-18 rounded-lg sm:w-36 md:w-72"
            sizes="(min-width: 768px) 288px, (min-width: 640px) 144px, 96px"
          />
        </a>
      )}
      <div className="flex flex-col justify-center space-y-3 sm:space-y-6">
        <h4 className="text-3xl font-bold">{recommendation.name}</h4>
        <p>{recommendation.description}</p>
        <Button
          as={'a'}
          href={recommendation.page?.path ?? ''}
          className="max-w-fit"
          size="lg"
        >
          Mehr erfahren <ArrowRight className="ml-1.5 size-5" />
        </Button>
      </div>
    </div>
  )
}
